<template>
  <li role="none" class="c-menu__submenu">
    <input ref="toggler" type="checkbox" class="menu-toggler" :id="htmlId">
    <label :for="htmlId" class="o-link c-menu__item__link c-submenu__toggle" role="menuitem">
      {{label}}
    </label>
    <OContainer tag="ul" role="menu" class="c-menu__container">
      <slot></slot>
    </OContainer>
  </li>
</template>

<script>
import htmlId from '@/mixins/htmlId'
import OContainer from '@/objects/containers/OContainer'

export default {
  name: 'CSubmenuItem',
  inject: ['menuBus'],
  components: {
    OContainer
  },
  props: ['label'],
  mixins: [htmlId],
  methods: {
    onActiveChild (e) {
      if (this.$refs.toggler) {
        this.$refs.toggler.checked = true
      }
    }
  },
  beforeMount () {
    this.menuBus.$on('active', this.onActiveChild)
  },
  mounted () {
    this.menuBus.$off('active', this.onActiveChild)
  },
  beforeUpdate () {
    this.menuBus.$on('active', this.onActiveChild)
  },
  updated () {
    this.menuBus.$off('active', this.onActiveChild)
  }
}
</script>

<style lang="scss">
  .c-menu__submenu {
    user-select: none;
    margin-bottom: $menu-vertical-margin;
  }

  .c-submenu__toggle {
    &, &.is-active {
      color: $primary;
      background-color: transparent;
    }

    .menu-toggler:not(:checked) ~ &:after {
      content: "►";
    }

    .menu-toggler:checked ~ &:after {
      content: "▼";
    }
  }
</style>
