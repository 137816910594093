<template>
  <component class="o-container" :is="tag">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'OContainer',
  props: {
    tag: {
      default: 'div'
    }
  }
}
</script>

<style lang="scss">
  .o-container {
    box-sizing: border-box;
    padding: 0 vr(1.25);

    @include two-column() {
      padding: 0 vr($container-desktop-right) 0 vr($container-desktop-left);
    }
  }
</style>
