<template>
  <component :is="component" :to="to" class="o-link" v-on="$listeners" :exact="exact">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'OLink',
  props: ['to', 'exact'],
  computed: {
    attributes () {
      const { class: c, ...attributes } = this.$attrs
      return attributes
    },
    component () {
      return this.to ? 'router-link' : 'a'
    }
  }
}
</script>

<style lang="scss">
  a, .o-link {
    color: $primary;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: 1px;
    outline: none;
    text-decoration: none;
    text-transform: uppercase;

    &:hover, &:focus {
      outline: none;
      text-decoration: none;
    }
  }
</style>
