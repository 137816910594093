<template>
  <li role="menuitem" class="c-menu__item">
    <o-link class="c-menu__item__link" :to="to" :href="href" v-on="$listeners"
            @click.native="onClick" :exact="exact">
      <slot></slot>
    </o-link>
  </li>
</template>

<script>
import OLink from '../objects/OLink'

export default {
  name: 'CMenuItem',
  inject: ['menuBus'],
  components: { OLink },
  props: ['to', 'href', 'exact'],
  computed: {
    isActive () {
      if (!this.to) return false
      try {
        const { route: { path, name } } = this.$router.resolve(this.to)
        if (name && name !== this.$route.name) return false
        return !(path && path !== this.$route.path)
      } catch (e) {
        return false
      }
    }
  },
  methods: {
    onClick (e) {
      this.menuBus.$emit('click', e)
    }
  },
  mounted () {
    if (this.isActive) {
      this.menuBus.$emit('active')
    }
  },
  updated () {
    if (this.isActive) {
      this.menuBus.$emit('active')
    }
  }
}
</script>

<style lang="scss">
  .c-menu__item {
    user-select: none;
    margin-bottom: $menu-vertical-margin;
  }

  .c-menu__item__link {
    padding: vr(0.25);
    margin: 0 (- vr(0.25));
    display: block;

    &:hover, &.is-active {
      background-color: $primary;
      color: #fff;
    }
  }
</style>
