<template>
  <div class="c-menu">
    <input ref="toggle" type="checkbox" class="menu-toggler" id="menu-toggler">
    <OContainer tag="ul" role="menu" class="c-menu__container c-menu__container--main">

      <c-menu-item v-if="$isMobile()" :to="{name: 'home'}" exact>Spoof Call</c-menu-item>
      <c-menu-item v-if="$isMobile()" :to="{name: 'bridge-call'}" exact>Bridge Call</c-menu-item>

      <c-menu-item v-if="!$isMobile()" :to="{name: 'home'}" exact>Spoof Call</c-menu-item>

      <c-menu-item v-if="!isLoggedIn" :to="{name: 'login'}" exact>Login</c-menu-item>
      <c-menu-item v-if="!isLoggedIn" :to="{name: 'register'}" exact>Join</c-menu-item>

      <c-menu-item v-if="isLoggedIn" :to="{name: 'recording-logs'}" exact>Recordings</c-menu-item>
      <c-menu-item v-if="isLoggedIn" :to="{name: 'credits'}" exact>Buy credits</c-menu-item>
      <c-menu-item :to="{name: 'free-credits'}" exact>Free Credits</c-menu-item>
      <c-menu-item v-if="isLoggedIn" :to="{name: 'affiliate'}" exact>Affiliate</c-menu-item>
      <c-submenu-item v-if="isLoggedIn" label="Settings">
        <c-menu-item :to="{name: 'password'}" exact>Change password</c-menu-item>
        <c-menu-item v-if="!isActive" :to="{name: 'verify'}" exact>Verify email</c-menu-item>
        <c-menu-item v-if="isLoggedIn" :to="{name: 'number-block'}" exact>
          Block number
        </c-menu-item>
      </c-submenu-item>
      <c-menu-item :to="{name: 'pricing'}" exact>Pricing</c-menu-item>
      <c-menu-item :to="{name: 'feedback'}" exact>Contact us</c-menu-item>
      <c-menu-item v-if="!isLoggedIn" :to="{name: 'number-block'}" exact>Block number</c-menu-item>
      <c-menu-item v-if="isLoggedIn" @click="logout">Logout</c-menu-item>
    </OContainer>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import CMenuItem from './CMenuItem'
import OContainer from '../objects/containers/OContainer'
import CSubmenuItem from './CSubmenuItem'
import Vue from 'vue'

const menuBus = new Vue({})

export default {
  name: 'CMenu',
  provide: {
    menuBus
  },
  components: { CSubmenuItem, OContainer, CMenuItem },
  computed: {
    ...mapGetters({
      isActive: 'isActive',
      isLoggedIn: 'isLoggedIn',
      credits: 'getCredits'
    })
  },
  methods: {
    ...mapMutations(['clearAuth', 'clearPhones']),
    logout () {
      this.clearAuth()
      this.clearPhones()
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' })
      }
    },
    onMenuClick (e) {
      if (this.$refs.toggle) {
        this.$refs.toggle.checked = false
      }
    }
  },
  mounted () {
    menuBus.$on('click', this.onMenuClick)
  },
  beforeDestroy () {
    menuBus.$off('click', this.onMenuClick)
  }
}
</script>

<style lang="scss">
.menu-toggler {
  display: none;
}

.c-menu {
  margin-bottom: $vertical-margin - $menu-vertical-margin;
}

.c-menu__container {
  display: none;
  list-style: none;
  margin: 0;

  .menu-toggler:checked ~ & {
    display: block;
  }

  @include two-column {
    &.c-menu__container--main {
      display: block;
    }
  }
}
</style>
