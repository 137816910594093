<template>
  <div class="c-message">
    <h3 v-if="title" class="c-message__title">{{title}}</h3>
    <p class="c-title__message">{{message}}</p>
    <OLink v-if="linkTo && linkLabel" :to="linkTo" class="c-message__link">{{linkLabel}}</OLink>
  </div>
</template>

<script>
import OLink from '../objects/OLink'

export default {
  name: 'CMessage',
  components: { OLink },
  props: {
    title: String,
    message: String,
    linkTo: Object,
    linkLabel: String
  }
}
</script>

<style lang="scss">
  .c-message {
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
    margin-bottom: $vertical-margin;
    padding-top: vr(0.5);
  }

  .c-message__title {
    color: $primary;
    text-transform: uppercase;
    margin: 0 0 vr(0.125);
  }

  .c-message__message {
    margin: 0;
  }

  .c-message__link {
    display: block;
    text-align: right;
    margin: vr(0.75) 0;
  }
</style>
