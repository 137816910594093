<template>
  <o-c-column class="l-default">
    <c-header></c-header>
    <div class="l-default__main">
      <c-sidebar class="l-default__main__sidebar" role="navigation"></c-sidebar>
      <o-container class="l-default__main__content" role="main" :tag="OCColumn">
        <CMessage v-if="globalMessage" :message="globalMessage" />
        <slot></slot>
      </o-container>
    </div>
    <c-footer></c-footer>
  </o-c-column>
</template>

<script>
import CHeader from '../components/CHeader'
import CFooter from '../components/CFooter'
import CSidebar from '../components/CSidebar'
import CMessage from '../components/CMessage'
import OCColumn from '../objects/containers/OCColumn'
import OContainer from '../objects/containers/OContainer'
import { mapGetters } from 'vuex'

export default {
  name: 'LDefault',
  components: { CMessage, OContainer, CSidebar, CFooter, CHeader, OCColumn },
  computed: {
    ...mapGetters({
      globalMessage: 'getMessage'
    }),
    OCColumn: () => OCColumn
  }
}
</script>

<style lang="scss">
  .l-default {
    min-height: 100vh;
    width: 100%;
    background: #f5f8ff;

    @include two-column() {
      background: linear-gradient(to left, #f5f8ff 50%, #fff 50%);
    }
  }

  .l-default__main {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;

    @include two-column() {
      flex-direction: row;
    }
  }

  .l-default__main__content,
  .l-default__main__sidebar {
    @include two-column() {
      flex: 1 1 50%;
      width: 50%;
    }
  }

  .l-default__main__content {
    flex-grow: 1;
  }
</style>
