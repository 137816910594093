<template>
  <div class="o-column"><slot></slot></div>
</template>

<script>
export default {
  name: 'OCColumn'
}
</script>

<style lang="scss">
  .o-column {
    display: flex;
    flex-flow: column nowrap;
  }
</style>
