<template>
  <o-container class="c-header">
    <c-menu-toggle></c-menu-toggle>
    <c-logo></c-logo>
  </o-container>
</template>

<script>
import CLogo from './CLogo'
import OContainer from '../objects/containers/OContainer'
import CMenuToggle from './CMenuToggle'

export default {
  name: 'CHeader',
  components: { CMenuToggle, OContainer, CLogo }
}
</script>

<style lang="scss">
  .c-header {
    padding-top: vr(0.5);

    @include two-column {
      margin-bottom: ($vertical-margin/4);
      width: 50%;
    }
  }
</style>
