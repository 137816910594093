<template>
  <o-link class="c-logo" :to="{name: 'home'}" title="spoof call">myphonerobot</o-link>
</template>

<script>
import OLink from '../objects/OLink'
export default {
  name: 'CLogo',
  components: { OLink }
}
</script>

<style lang="scss">
  .c-logo {
    color: $primary;
    display: inline-block;
    @include typi('logo');
    font-weight: bold;
    text-align: left;
    text-transform: lowercase;
    user-select: none;
  }

  // © to the left of logo
  .c-logo {
    position: relative;

    &:before {
      content: "©";
      display: inline-block;
      position: absolute;
      left: -0.825em;
      top: 0;
    }
  }
</style>
