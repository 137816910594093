<template>
  <div>
    <div class="c-footer o-tooltip-container">
      <span class="c-footer__item o-tooltip-trigger o-tooltip-trigger--hover">&copy;</span>
      <o-tooltip anchor="left">
        <div>&copy; {{new Date().getFullYear()}} myphonerobot.com</div>
        <div>All Rights Reserved</div>
      </o-tooltip>
      <o-link class="c-footer__item" href="https://secondphonenumber.io" target="_blank">Second Number</o-link>
      <o-link class="c-footer__item" :to="{name: 'terms'}">Terms</o-link>
      <o-link class="c-footer__item" :to="{name: 'privacy'}">Privacy</o-link>
    </div>
    <div class="legal">
      <b>Legal address:</b> Triadisa District 2A, Emiliyan Stanev Street, Sofia, Bulgaria
    </div>
  </div>
</template>

<script>
import OLink from '../objects/OLink'

const OTooltip = () => import('../objects/OTooltip')

export default {
  name: 'CFooter',
  components: { OTooltip, OLink }
}
</script>

<style lang="scss">
  $footer-horizontal-margin: (vr(1.75) - 3 * vr(0.25) - 0.8rem);

  .legal {
    margin-left: (4 * $footer-horizontal-margin);
    margin-right: (3 * $footer-horizontal-margin);
    font-size: 0.75em;
  }

  .c-footer {
    margin: vr(1.5) auto 0;
    padding: 0.5em 0 0.25em;
    box-sizing: border-box;

    @include two-column {
      margin: vr(1.5) 0 0;
    }
  }

  .c-footer__item {
    display: inline-block;
    border-left: 1px solid #666;
    padding: 0 vr(0.25);

    &:first-child {
      border-left: none;
      margin-left: $footer-horizontal-margin;
    }

    &:last-child {
      margin-right: $footer-horizontal-margin;
    }
  }

  .c-footer__item--first {
    margin-left: $footer-horizontal-margin;
  }

  .c-footer__item--last {
    margin-right: $footer-horizontal-margin;
  }

  .c-addthis {
    margin-top: vr(0.3);
  }
</style>
