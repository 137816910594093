export default {
  props: {
    id: String
  },
  data: function () {
    return {
      htmlId: null
    }
  },
  mounted () {
    this.htmlId = this.id || this._uid
  }
}
