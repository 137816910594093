<template>
  <OContainer v-if="hasCallout" class="c-callout__container">
    <h1 class="c-callout">{{ defaultMessage }}</h1>
  </OContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import OContainer from '../objects/containers/OContainer'

export default {
  name: 'CCallout',
  components: { OContainer },
  data () {
    return {
      defaultMessage: 'spoof call and caller id faker,\nfree spoof call to try'
    }
  },
  computed: {
    ...mapGetters(['hasCallout', 'getCalloutMessage', 'isLoggedIn'])
  }
}
</script>

<style lang="scss">
.c-callout {
  font-weight: normal;
  margin: 0;
  white-space: pre-wrap;
  @include typi('callout');

  &__container {
    margin-bottom: $vertical-margin;
  }
}

</style>
