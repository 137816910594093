<template>
  <!--suppress XmlInvalidId -->
  <label for="menu-toggler" class="c-menu-toggle">
  </label>
</template>

<script>
export default {
  name: 'CMenuToggle'
}
</script>

<style lang="scss">
  .c-menu-toggle {
    cursor: pointer;
    float: right;
    user-select: none;
    color: $primary;
    @include typi('menu-toggle');
    padding: 0 vr(0.25);
    margin-right: (- vr(0.25));
    margin-left: vr(0.5);
    width: 0.75em;
    height: 1em;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23#{unquote('#{str-slice(ie-hex-str($primary), 4)}')}' d='M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2 s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2 S29.104,22,28,22z'/%3E%3C/svg%3E") center/contain no-repeat;

    @include two-column() {
      display: none;
    }
  }
</style>
