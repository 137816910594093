<template>
  <div class="c-sidebar">
    <c-callout></c-callout>
    <c-menu></c-menu>
  </div>
</template>

<script>
import CMenu from './CMenu'
import CCallout from './CCallout'

export default {
  name: 'CSidebar',
  components: { CCallout, CMenu }
}
</script>

<style lang="scss">
  .c-sidebar {
    margin-bottom: 0;
  }
</style>
